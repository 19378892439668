<template>
  <v-container fluid class="d-flex flex-column pa-0 fill-height flex-nowrap">
    <router-link to="/book-selection" replace class="mx-auto mt-16 mb-6">
      <v-img :src="`${cdnUrl}/assets/images/item/title_select_ai.svg`" width="125" alt="選書AI 2.0"></v-img>
    </router-link>

    <v-btn
      @click.stop="closeBookSelection"
      retain-focus-on-click
      text
      class="pa-0 ml-auto mr-6 mt-6"
      min-width="auto"
      min-height="auto"
      max-width="32"
      max-height="32"
      absolute
      right
      style="right: 0"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-sheet class="d-flex flex-column-reverse" width="100%">
      <template v-for="item in getChatItems">
        <v-card
          flat
          v-if="isChatLoading(item.id + 1, 'comment')"
          class="mr-auto py-4"
          style="padding-left: 10%"
          width="100%"
          max-width="100%">
          <div class="dot-flashing ml-4"></div>
        </v-card>
        <v-alert
          v-if="item.role === 'user'"
          :key="item.id"
          :id="'user-chat-' + item.id"
          color="#F5F5F5"
          class="rounded-xl pa-4 pa-md-8 ml-auto"
          max-width="800"
          width="60%"
          :style="smAndDown ? 'margin-right: 5%' : 'margin-right: 10%'">
          {{ item.content }}
        </v-alert>

        <v-card
          flat
          v-if="item.role === 'assistant'"
          :key="item.id"
          class="mr-auto assistant-chat-item"
          :style="smAndDown ? 'padding-left: 5%' : 'padding-left: 10%'"
          width="100%"
          max-width="100%">
          <v-card-text v-if="item.content.text" class="pl-0 black--text text-body-2" style="padding-right: 12%">
            {{ item.content.text }}
          </v-card-text>
          <div v-if="isChatLoading(item.id + 1, 'clusters')" class="py-4">
            <div class="dot-flashing ml-4"></div>
          </div>

          <template v-for="(result, idx) in item.content.searchResults">
            <template v-if="result.isLoading">
              <v-card-title v-if="result.title" class="pa-0 mb-2 text-h6 font-weight-bold">{{ `${idx + 1}. ${result.title}` }}</v-card-title>
              <v-card-subtitle v-if="result.description" class="pa-0 mt-0 mb-3 black--text text-body-2">{{ result.description }}</v-card-subtitle>
              <v-skeleton-loader
                class="d-flex mb-3"
                type="image@5"
              ></v-skeleton-loader>
            </template>
            <template v-else>
              <template v-if="result.error">
                <v-card-title v-if="result.title" class="pa-0 mb-2 text-h6 font-weight-bold">{{ `${idx + 1}. ${result.title}` }}</v-card-title>
                <v-card-subtitle v-if="result.description" class="pa-0 mt-0 mb-3 black--text text-body-2">{{ result.description }}</v-card-subtitle>
                <v-card-text>{{ result.errorMessage }}</v-card-text>
              </template>
              <slide-item
                v-else
                class="slide-items"
                v-bind="{
                ...(result.title ? { title: `${idx + 1}. ${result.title}` } : null),
                ...(result.description ? { subtitle: result.description } : null)
              }"
                :items="result.items"
                min-height="auto"
                layout="fixed-column"
                :fixed-width="smAndDown ? '130' : '160'"
                :key="`${item.id}-${idx + 1}`"
              >
              </slide-item>
            </template>
          </template>
        </v-card>
      </template>
    </v-sheet>

    <v-sheet
      width="100%"
      class="d-flex flex-column pb-0 pt-1 px-3 mt-auto"
      style="z-index: 1; background-color: #FFF;position: sticky; bottom: 0;">
      <v-btn
        v-visible="!isScrollBottom"
        icon
        @click="scrollToBottom"
        elevation="2"
        color="#2c2c2c"
        class="d-block mx-auto mb-4"
        style="position: absolute;top: -20px; left: 0; right: 0; bottom: 0;background-color: #FFF">
        <v-icon>mdi-arrow-down</v-icon>
      </v-btn>

      <v-card flat width="100%" max-width="800" class="mx-auto" color="transparent">
        <v-btn
          @click="clearHistory"
          text
          small
          :disabled="isSubmitDisable"
          color="#2c2c2c"
          class="d-block ml-auto px-0 mb-1 text-caption"
          style="background-color: #FFF;z-index: 1">
          <v-icon small>mdi-plus</v-icon>
          チャットを新規作成
        </v-btn>
        <v-textarea
          @keydown="execute"
          solo
          flat
          background-color="#F5F5F5"
          name="chat-input"
          v-model="inputText"
          hide-details="auto"
          class="rounded-xl rounded-b-0 auto-resize-textarea"
          :label="labelText"
          :disabled="isSubmitDisable"
          :rules="[v => !v || v.length <= maxLength || maxLength + '文字以内で入力してください']"
          @update:error="onError"
          @input="adjustHeight"
          ref="textarea"
          :rows="1"
          no-resize
        >
          <template #append>
            <v-btn
              icon
              :style="isSubmitDisable || isButtonDisable ? 'background-color: #cacaca !important;' : 'background-color: #009098 !important;'"
              class="rounded"
              @click="queryExecute"
              width="36"
              height="36"
              :disabled="isSubmitDisable || isButtonDisable"
            >
              <v-icon color="white" small style="color: white !important;">mdi-send</v-icon>
            </v-btn>
          </template>
        </v-textarea>
      </v-card>
    </v-sheet>
    <audio-controller v-if="!isAudioControllerExists" />
    <v-dialog
      v-model="getRateLimitError.status"
      max-width="320"
      @click:outside="closeBookSelection"
    >
      <v-card>
        <v-card-text class="pt-6" style="white-space: break-spaces;">{{ getRateLimitError.errorMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeBookSelection"
          >閉じる</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import SlideItem from "../top/slide-item.vue";
import AudioController from "../../molecules/item/audio-controller.vue";
import bookSelectionConfig from "../../../config/bookSelection.js";
const {mapGetters, mapActions, mapMutations} = createNamespacedHelpers('openAi');

export default {
  name: "vb-ai-chat-result",
  components: {AudioController, SlideItem},
  data() {
    return {
      cdnUrl: VueConfig.cdn_url,
      isScrollBottom: false,
      isAudioControllerExists: false,
      isError: false,
      maxLength: bookSelectionConfig.maxNumberOfInput,
      labelText: bookSelectionConfig.labelText
    }
  },
  methods: {
    ...mapActions([
      'executeClusterSearch'
    ]),
    ...mapMutations([
      'mutateInputText',
      'addChatItems',
      'updateChatItemId',
      'clearChatHistory'
    ]),
    closeBookSelection() {
      if(this.getParentURL) {
        this.$router.push({path: this.getParentURL});
      } else {
        history.back();
      }
    },
    execute(event) {
      if(this.isSubmitDisable) {
        event.preventDefault();
        return;
      }
      if (event.keyCode === 13 && !event.shiftKey && !event.ctrlKey && !event.altKey) {
        this.queryExecute()
        event.preventDefault(); // テキストエリアに改行を反映させないようにする
      }
    },
    queryExecute() {
      // ボタンが無効化されている場合または入力が空白のみの場合は送信しない
      if(this.isButtonDisable || !this.inputText || /^[\s　]+$/.test(this.inputText)) {
        return;
      }
      this.addChatItemsAndScroll();
      this.executeClusterSearch();
    },
    // チャット欄にメッセージを追加
    addChatItemsAndScroll() {
      this.updateChatItemId();
      this.addChatItems({
        id: this.getChatItemId,
        role: 'user',
        content: this.getInputText,
      });
      // 指定のIDセレクタの位置までスクロール
      this.scrollToCurrentChat();
      this.sendEventToGoogleAnalytics();
    },
    scrollToBottom() {
      const header = document.querySelector('header');
      const headerHeight = header ? header.offsetHeight : 0;

      // ドキュメントの最下部から、ヘッダーの高さ分を引いた位置にスクロール
      window.scrollTo({
        top: document.documentElement.scrollHeight - headerHeight,
        behavior: 'smooth'
      });
    },
    scrollToCurrentChat() {
      this.$nextTick(() => {
        setTimeout(() => {
          const targetId = 'user-chat-' + this.getFirstUserChatItemId();
          const element = document.getElementById(targetId);

          if (element) {
            // ヘッダーの高さを考慮（81px）
            const headerHeight = this.smAndDown ? 61 : 81;

            // 要素の位置情報を取得
            const rect = element.getBoundingClientRect();

            // ウィンドウの現在のスクロール位置 + 要素のページ上部からの位置 - ヘッダーの高さ
            const scrollPosition = window.scrollY + rect.top - headerHeight;

            // 余白設定（必要に応じて調整）
            const topMargin = 20;

            window.scrollTo({
              top: scrollPosition - topMargin,
              behavior: 'smooth'
            });
          }
        }, 100);
      });
    },
    // chatItemsの先頭のrole === userのIDを取得
    getFirstUserChatItemId() {
      const chatItems = this.getChatItems;
      for (let i = 0; i < chatItems.length; i++) {
        if (chatItems[i].role === 'user') {
          return chatItems[i].id;
        }
      }
      return null;
    },
    // スクロールが最下部にあるかどうか
    scrollToTheBottom() {
      const docElement = document.documentElement;
      const scrollHeight = docElement.scrollHeight;
      const scrollTop = docElement.scrollTop;
      const clientHeight = docElement.clientHeight;

      if (scrollHeight === clientHeight) {
        this.isScrollBottom = true;
      } else {
        this.isScrollBottom = scrollHeight - scrollTop === clientHeight;
      }
    },
    clearHistory() {
      this.clearChatHistory();
      this.addChatItems({
        role: 'assistant',
        content: {text: '履歴が削除されました。'},
      });
      this.scrollToTheBottom();
    },
    onError(errorState) {
      this.isError = errorState;
    },
    adjustHeight() {
      const textarea = this.$refs.textarea.$el.querySelector('textarea');
      const minHeight = 44;
      const maxHeight = 100;

      if (!textarea) return;

      // 空の場合は最小の高さに戻す
      if (!this.inputText) {
        textarea.style.cssText += `height: ${minHeight}px !important; overflow-y: hidden !important;`;
        return;
      }

      // 現在のスクロール位置を保存
      const savedScrollTop = textarea.scrollTop;

      // 高さをリセットしてスクロール高さを取得
      textarea.style.cssText += 'height: auto !important;';
      const scrollHeight = textarea.scrollHeight;

      if (scrollHeight > maxHeight) {
        textarea.style.cssText += `height: ${maxHeight}px !important; overflow-y: auto !important;`;
      } else if (scrollHeight > minHeight) {
        textarea.style.cssText += `height: ${scrollHeight}px !important; overflow-y: hidden !important;`;
      } else {
        textarea.style.cssText += `height: ${minHeight}px !important; overflow-y: hidden !important;`;
      }

      // スクロール位置を復元
      textarea.scrollTop = savedScrollTop;
    },
    // Google Analyticsにイベントを送信
    sendEventToGoogleAnalytics() {
      if(!this.getInputText) {
        return;
      }
      gtag('event', 'bookSelectionVer2', {
        "book_selection_ver2": this.getInputText,
      });
    },
  },
  computed: {
    ...mapGetters([
      'getInputText',
      'getParentURL',
      'getChatItems',
      'getChatItemId',
      'isChatLoading',
      'getRateLimitError',
      'getPendingRequests',
      'isAllClusterSearchLoaded',
      'getIsComplete'
    ]),
    inputText: {
      get() {
        return this.getInputText;
      },
      set(value) {
        this.mutateInputText(value);
      }
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isSubmitDisable() {
      return this.getRateLimitError.status || this.isPendingRequest
    },
    isButtonDisable() {
      return !this.getInputText || this.getInputText === '' || this.isError
    },
    isPendingRequest() {
      return Object.keys(this.getPendingRequests).length > 0 || !this.isAllClusterSearchLoaded;
    }
  },
  mounted() {
    // フッターを非表示にする
    document.getElementsByTagName('footer')[0].style.display = 'none';
    if(this.smAndDown) {
      document.getElementsByTagName('main')[0].style.minHeight = 'calc(100vh - 111px)';
    }
    window.addEventListener('scroll', this.scrollToTheBottom);
    this.$nextTick(() => {
        setTimeout(() => {
          this.scrollToCurrentChat();
          this.scrollToTheBottom();
      }, 300);
    });
  },
  beforeDestroy() {
    // フッターを表示する
    document.getElementsByTagName('footer')[0].style.display = 'flex';
    document.getElementsByTagName('main')[0].style.minHeight = 'unset';
    window.removeEventListener('scroll', this.scrollToTheBottom);
  },
  watch: {
    getIsComplete(val){
      if (val) {
        this.scrollToTheBottom();
      }
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-textarea {
    .v-input__append-inner {
      margin-top: auto;
      margin-bottom: 12px;
    }
    textarea {
      border: 0 !important;
      margin-bottom: 10px;
    }
    .v-label {
      max-width: 95%;
      line-height: 1.2em;
      top: 10px !important;
      height: auto;
      font-size: 14px;
    }
  }
  .auto-resize-textarea {
    textarea {
      transition: height 0.05s ease;
      min-height: 44px !important;
      max-height: 100px !important;
      resize: none !important;
      overflow-y: hidden !important;
    }

    &.scrollable {
      textarea {
        overflow-y: auto !important;
      }
    }
  }
  .slide-items {
    .col {
      .v-card__title {
        font-size: 20px !important;
      }
    }
    .v-card {
      margin-left: 0 !important;
      margin-bottom: 16px !important;
      &__title.word-break {
        word-break: normal;
      }
    }
  }
  .v-text-field {
    .v-label {
      overflow: unset;
      white-space: normal;
    }
  }
  .v-skeleton-loader__image {
    width: 160px;
    height: 220px;
    margin-right: 16px;
    flex: 0 0 auto;
    border-radius: inherit;
    @media (max-width: 960px) {
      width: 130px;
      height: 180px;
    }
  }
  .assistant-chat-item {
    .v-card__title {
      margin-right: 12%;
    }
    .v-card__subtitle {
      font-weight: normal !important;
      margin-right: 12%;
    }
    @media (max-width: 960px) {
      .v-card__title {
        margin-right: 6%;
      }
      .v-card__subtitle {
        margin-right: 6%;
      }
    }
  }
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #eee;
  color: #eee;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #eee;
  color: #eee;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #eee;
  color: #eee;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}
@keyframes dot-flashing {
  0% {
    background-color: #eee;
  }
  50%, 100% {
    background-color: rgba(49, 46, 59, 0.2);
  }
}
</style>