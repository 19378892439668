<template>

    <v-card tile flat>
      <v-btn
          @click.stop="closeBookSelection"
          retain-focus-on-click
          text
          class="pa-0"
          min-width="auto"
          min-height="auto"
          max-width="32"
          max-height="32"
          style="position: absolute; top: -40px; right: 24px"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-sheet width="100%" max-width="800" class="mt-12 mt-md-16 mx-auto" style="overflow: auto">
        <v-img :src="`${cdnUrl}/assets/images/item/title_select_ai.svg`" width="125" class="mx-auto mb-2" alt="選書AI 2.0"></v-img>
        <v-list-item class="pt-4 pt-md-2 pl-6 pr-4 d-flex flex-column" style="line-height: normal">
          <v-list-item-title class="text-body-1 text-md-h5 font-weight-bold mb-2" style="white-space: normal">
            あなたにぴったりな本を見つけるために
          </v-list-item-title>
          <v-list-item-subtitle class="text-caption text-md-body-2 font-weight-bold" style="color: #2c2c2c;white-space: normal">
            あなたの情報をたっぷり教えてください！選書AIがあなたに最適な本を提案します
          </v-list-item-subtitle>
        </v-list-item>

        <v-tabs
            v-model="tab"
            align-with-title
            class="mb-8"
            style="border-bottom: 1px solid #2C2C2C"
        >
          <v-tabs-slider color="#2C2C2C"></v-tabs-slider>
          <v-tab
              v-for="(item, index) in tabItems"
              :key="index"
              class="ml-0 text--secondary text-caption text-md-body-2"
              :class="xxsAndDown ? 'px-1' : null"
              active-class="font-weight-bold grey--text text--darken-4"
              :disabled="index === 1"
          >
            {{ item }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item class="px-4 px-md-0 pb-4 pb-md-0">
            <v-textarea
                @keydown="execSearch"
                solo
                flat
                background-color="#F5F5F5"
                name="chat-input"
                v-model="inputText"
                hide-details="auto"
                class="mb-8 rounded-xl"
                :label="labelText"
                :rules="[v => !v || v.length <= maxLength || maxLength + '文字以内で入力してください']"
                @update:error="onError"
            >
              <template #append>
                <v-btn
                  icon
                  :style="isButtonDisable ? 'background-color: #cacaca !important;' : 'background-color: #009098 !important;'"
                  class="rounded"
                  @click="saveChatItems"
                  width="36"
                  height="36"
                  :disabled="isButtonDisable"
                >
                  <v-icon color="white" small style="color: white !important;">mdi-send</v-icon>
                </v-btn>
              </template>
            </v-textarea>

            <v-row dense no-gutters>
              <v-col v-for="(text, index) in shuffledSampleText" :key="index" cols="12" sm="6" md="6" class="mb-6">
                <v-card
                    @click="inputText = text"
                    text
                    flat
                    :width="smOnly ? 'calc(100% - 12px)' : '100%'"
                    :max-width="smAndDown ? '100%' : 380"
                    height="100%"
                    class="rounded-xl"
                    :class="index % 2 === 0 ? 'mr-auto ml-md-2' : 'ml-auto mr-md-2'"
                    style="border: 1px solid #65E0D9;"
                >
                  <v-card-text class="pa-6" style="color: #2c2c2c">{{ text }}</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-sheet>
    </v-card>

</template>

<script>
import {createNamespacedHelpers} from "vuex";
import vbAiChat from "../../molecules/vb-ai-chat.vue";
import VbAiChatResult from "./vb-ai-chat-result.vue";
import bookSelectionConfig from "../../../config/bookSelection.js"

const {mapState, mapGetters, mapActions, mapMutations} = createNamespacedHelpers('openAi');

export default {
  name: 'vb-ai-chat',
  components: {
    VbAiSearchResult: VbAiChatResult,
    vbAiChat
  },
  data() {
    return {
      cdnUrl: VueConfig.cdn_url,
      sampleText: [
        '30代会社員。読書好き。最近、心に残った言葉がある。この気持ちに合う本を読みたい。',
        '20代フリーランス。時間の使い方が下手かも。もっと上手に過ごせる本が知りたい。効率重視。',
        '40代です。子どものころ好きだった本を思い出したい。懐かしくなるような本を読みたいな。',
        '会社員です。仕事に疲れ気味。仕事のやる気が出ない…。元気をもらえる本を探してます。',
        '30代。最近気持ちが沈みがちでなんとなく気持ちが落ちる日が多い。心が軽くなる本を知りたいです。',
        '20代、映画好きです。映画の世界観がすごく好きだった！似た雰囲気の本を読んでみたい。',
        '最近、気候変動についての気になるニュースがあった。もっと深く知れる本をカジュアルに読んでみたい。',
        '30代カフェ好き。のんびり読書派です。コーヒーと読書が好き。そんな気分に合う本ってある？',
        '人生の選択に迷い中のIT系30代です。これからの生き方を考え中。ヒントになりそうな本を教えてほしい。',
        '本を読む習慣をつけたい20代です。久しぶりに小説を読みたい。どっぷりハマれる本を探してます。',
        '20代の頃ヨーロッパの小さな村に行ったら、時間の流れが違って感じた。ゆったりした旅気分になれる本を知りたい。',
        '友人に本をプレゼントしてみたい。友人は落ち着いた小説が好きなようです。おすすめの本は？',
        '新しいことを知りたい。ふと気になったことを深く知りたい。今まで知らなかった価値観や考え方に触れられる本をお願い。',
        'ファンタジー好きです。推理ものも好き。じっくり読める長編小説を知りたい。',
        '40代主婦。言葉の響きが美しくて、大切に読みたくなるような本を探しています。おすすめはありますか？'
      ],
      tab: null,
      tabItems: ['テキストで教えてあげる', '本の好みで教えてあげる'],
      shuffledSampleText: [],
      isError: false,
      maxLength: bookSelectionConfig.maxNumberOfInput,
      labelText: bookSelectionConfig.labelText
    }
  },
  created() {
    this.setShuffleSampleText();
  },
  methods: {
    ...mapActions(['executeClusterSearch']),
    ...mapMutations([
      'mutateIsDisplayAiChatNavbar',
      'mutateInputText',
      'addChatItems',
      'updateChatItemId',
    ]),
    closeBookSelection() {
      if (this.getParentURL) {
        this.$router.push({path: this.getParentURL});
      } else {
        history.back();
      }
    },
    async execSearch(event) {
      if (event.keyCode === 13 && !event.shiftKey && !event.ctrlKey && !event.altKey) {
        this.saveChatItems();
        event.preventDefault();
      }
    },
    saveChatItems() {
      // ボタンが無効化されている場合または入力が空白のみの場合は送信しない
      if(this.isButtonDisable || !this.inputText || /^[\s　]+$/.test(this.inputText)) {
        return;
      }
      this.updateChatItemId();
      this.addChatItems({
        id: this.getChatItemId,
        role: 'user',
        content: this.inputText,
      });
      this.sendEventToGoogleAnalytics();
      this.executeClusterSearch();
      this.$router.replace({path: '/book-selection/result'});
    },
    setShuffleSampleText() {
      const sampleText = this.sampleText;
      // ランダムなサンプルテキストを4件取得
      const indices = new Set();
      while (indices.size < 4) {
        indices.add(Math.floor(Math.random() * sampleText.length));
      }
      this.shuffledSampleText = Array.from(indices).map(i => sampleText[i]);
    },
    onError(errorState) {
      this.isError = errorState;
    },
    // Google Analyticsにイベントを送信
    sendEventToGoogleAnalytics() {
      if(!this.inputText) {
        return;
      }
      gtag('event', 'bookSelectionVer2', {
        "book_selection_ver2": this.inputText,
      });
    },
  },
  computed: {
    ...mapGetters([
      'getInputText',
      'getParentURL',
      'getChatItemId'
    ]),
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    smOnly() {
      return this.$vuetify.breakpoint.smOnly;
    },
    xxsAndDown() {
      return this.$vuetify.breakpoint.width <= 320;
    },
    currentRoute() {
      return this.$route.path;
    },
    // 非表示対象のルートがある場合はtrueを返す
    isVisible() {
      const hiddenRoutes = [
        '/confirm',
        '/completed',
        '/shipping/form',
        '/shipping/confirm',
        '/card/form',
      ];
      return !hiddenRoutes.includes(this.currentRoute);
    },
    inputText: {
      get() {
        return this.getInputText;
      },
      set(value) {
        this.mutateInputText(value);
      }
    },
    isButtonDisable() {
      return !this.getInputText || this.getInputText === '' || this.isError
    }
  },
  mounted() {
    // フッターを非表示にする
    document.getElementsByTagName('footer')[0].style.display = 'none';
  },
  beforeDestroy() {
    document.getElementsByTagName('footer')[0].style.display = 'flex';
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-dialog {
    margin: 0 !important;
    max-height: fit-content !important;
  }
  .v-textarea {
    .v-input__append-inner {
      margin-top: auto;
      margin-bottom: 12px;
    }
    textarea {
      border: 0 !important;
      margin-bottom: 10px;
      resize: none;
    }
  }

  .v-text-field {
    .v-label {
      white-space: normal;
      max-width: 95%;
      height: auto;
      line-height: 1.4em;
      font-size: 14px;
    }
  }

  .v-slide-group__content {
    max-width: 100%;
  }
}
</style>